import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { SignUpForm } from "../components/PageComponents/Signup";
import { LoginType } from "../enums/LoginType";
import LoginLayout from "../components/LoginLayout";

export default function SignIn() {
  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      AOS.init();
    }
  }, []);

  return (
    <LoginLayout
      alwaysDisplayNavLogo
      title="Sign In | Bidddy - The world's first Instagram Automated Auction Platform"
    >
      <SignUpForm loginType={LoginType.SignIn} />
    </LoginLayout>
  );
}
